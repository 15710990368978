import React, { Component } from 'react';
import './App.css';
import './custom.css';

import Header from './inc/header';
import NavBar from './inc/navbar';

import RequireAuth from './inc/requireAuth';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Home from './pages/home'
import About from './pages/about'
import Login from './pages/login'
import Register from './pages/register'
import Info from './pages/info'
import Help from './pages/help'
import Unauthorized from './pages/unauthorized';
import Error from './pages/error';

import SelectProject from './pages/select_project';
import EditProject from './pages/edit_project';
import ProjectHome from './pages/project_home';
import ProjectSetup from './pages/project_setup';
import CreateRoom from './pages/room_editor';
import RoomCreateItem from './pages/room_create_item';
import RoomEditItem from './pages/room_edit_item';
import RoomItems from './pages/room_list_items';
import TestTime from './pages/test_time';
import Account from './pages/account';

import AdminHome from './pages/admin/admin_home';
import AdminEditRooms from './pages/admin/edit_room';

import Layout from './inc/layout';

// Could put roles lookup table here to make routes more obvious
import ROLES from './config/roles';


import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

class App extends Component {
state = {
    data: null
  };

  componentDidMount() {
    this.callBackendAPI()
      .then(res => this.setState({ data: res.message, result: res.type }))
      .catch(err => console.log(err));
  }
    // fetching the GET route from the Express server which matches the GET route from server.js
    // https://daveceddia.com/deploy-react-express-app-heroku/
    // https://www.freecodecamp.org/news/how-to-create-a-react-app-with-a-node-backend-the-complete-guide/
    // https://redux.js.org/usage/configuring-your-store/
    // https://blog.openreplay.com/using-jwt-for-authentication-in-react/
    // https://www.freecodecamp.org/news/how-to-use-axios-with-react/
    
  callBackendAPI = async () => {
    const response = await fetch('/test_db');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          {/* <p className={"App-intro alert alert-ad-" + (this.state.result ?? "danger")}>{this.state.data ?? "Error: Unable to connect to API"}</p> */}
          <NavBar />
        </div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="info" element={<Info />} />
            <Route path="about" element={<About />} />
            <Route path="help" element={<Help />} />
            <Route path="unauthorized" element={<Unauthorized />} />
          </Route>

          {/* Internal routes, requires login */}
          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Reseller]}/>}>          
            <Route path="selectproject" element={<SelectProject />} />
            <Route path="editproject" element={<EditProject />} />
            <Route path="projecthome" element={<ProjectHome />} />
            <Route path="projectsetup" element={<ProjectSetup />} />
            <Route path="edititem" element={<RoomEditItem />} />
            <Route path="createroom" element={<CreateRoom />} />
            <Route path="createitem" element={<RoomCreateItem />} />
            <Route path="roomitems" element={<RoomItems />} />
            <Route path="account" element={<Account />} />
          </Route>

          {/* Requires editor access, etc. */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Reseller]}/>}>  
            <Route path="test_time" element={<TestTime />} />
            <Route path="admin/home" element={<AdminHome />} />
            <Route path="admin/editroom" element={<AdminEditRooms />} />
          </Route>

          <Route path="*" element={<Error />} />
          {/* fix this with https://ui.dev/react-router-cannot-get-url-refresh ??? */}
        </Routes>
      </Router>
    );
  }
}

export default App;