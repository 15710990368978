import {React, useRef, useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const FETCH_PROJECT_URL = "/project";
const UPDATE_PROJECT_URL = "/project";
const DELETE_PROJECT_URL = "/project";

const EditProject = () => {
    
    const { auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const focusField = useRef();
    const alertRef = useRef();

    const [project, setProject] = useState({});
    const [dirtyInputs, setDirtyInputs] = useState(false);
    
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [allowDelete, setAllowDelete] = useState(false);

    const [inputs, setInputs] = useState({});
    const [alertMsg, setAlertMsg] = useState("");
    const [success, setSuccess] = useState(false);
    
    const params = new URLSearchParams(location.search);
    const projectID = params.get('projectID');

    // Focus on the current field
    useEffect(() => {
        focusField.current.focus();
    }, []);

    // Go back if no project ID was given
    useEffect(() => {
        if (!projectID) {
            navigate(-1);
        }
    }, [projectID, navigate]);

    // Get the project details of the given project
    useEffect(() => {
        try {
            if (projectID) {
                axios.get(FETCH_PROJECT_URL + "?projectID=" + projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => {
                        const projectData = response?.data?.project;
                        console.log(projectData);
                        setProject(projectData);
                        setInputs(projectData);
                        setDirtyInputs(false);
                    }
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [projectID, auth]);

    useEffect(() => {
        // Don't clear success message when inputs automatically cleared
        if (!success) {
            setAlertMsg('');
        }
    }, [inputs, success]);

    const updateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        setDirtyInputs(true);
    };

    const updateConfirmation = (event) => {
        const confirmation = event.target.value;
        setDeleteConfirmation(confirmation);

        if (project.name && confirmation === project.name) {
            setAllowDelete(true);
        }
        else {
            setAllowDelete(false);
        }
    }

    const updateProject = (event) => {
        event.preventDefault();
        if (projectID) {
            axios.post(UPDATE_PROJECT_URL,
            JSON.stringify({projectID, inputs}),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                },
                withCredentials: true
            }
            ).then(function (response) {
                if (response?.status === 200) {
                    setAlertMsg('Successfully updated the project!');
                    setSuccess(true);
                    setDirtyInputs(false);
                    navigate('/selectproject');
                }
                else {
                    console.log('Unexpected response', response);
                }
            }).catch(function (err) {
                setSuccess(false);
                if (!err?.response) {
                    setAlertMsg('No server response');
                }
                else {
                    setAlertMsg('Project update failure: ', JSON.stringify(err));
                }
            });
        }
    };
    
    const goHome = () => navigate('/selectproject');
    const projectHome = () => navigate('/projecthome?projectID=' + projectID);

    const deleteProject = (event) => {
        event.preventDefault();
        axios.delete(DELETE_PROJECT_URL + '?projectID=' + projectID,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.accessToken}`
            },
            withCredentials: true
        }
        ).then(function (response) {
            if (response?.status === 200) {
                navigate('/selectproject');
            }
            else {
                console.log('Unable to delete project:', response);
            }
        }).catch( function (err) {
            setSuccess(false);
            if (!err?.response) {
                setAlertMsg('No server response');
            }
            else {
                setAlertMsg('Unable to delete project: ', JSON.stringify(err));
            }
        });
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">

                    {/* Header with breadcrumb navigation */}
                    <div className="card-header">
                        <div className="d-flex justify-content-center">
                            <nav className="breadcrumb" style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item" onClick={goHome}>Home</li>
                                    <li className="breadcrumb-item" onClick={projectHome}>{project.name}</li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Project</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="card-body">

                        <p ref={alertRef} className={alertMsg ? (success ? "alert alert-ad-success" : "alert alert-ad-danger") :"offscreen"} aria-live="assertive">{alertMsg}</p>

                        <form className="row g-3">

                            <div className="col-12">
                                <label htmlFor="name" className="form-label">Project Name</label>
                                <input
                                    type="text"
                                    required
                                    ref={focusField}
                                    className="form-control"
                                    name="name"
                                    id="name"
                                    onChange={updateInput}
                                    value={inputs.name || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <label htmlFor="client" className="form-label">Company or Client (optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="client"
                                    id="client"
                                    onChange={updateInput}
                                    value={inputs.client || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Project Address</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="address"
                                    id="address"
                                    onChange={updateInput}
                                    value={inputs.address || ""}/>
                            </div>

                            <div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address2"
                                    id="address2"
                                    onChange={updateInput}
                                    value={inputs.address2 || ""}
                                    placeholder="Apartment, studio, or floor" />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="city"
                                    id="city"
                                    onChange={updateInput}
                                    value={inputs.city || ""}
                                    />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="state"
                                    id="state"
                                    onChange={updateInput}
                                    value={inputs.state || ""}
                                    />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="zip"
                                    id="zip"
                                    onChange={updateInput}
                                    value={inputs.zip || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <div className="d-grid gap-3 d-md-flex">
                                    <button className="btn btn-ad-success" disabled={!dirtyInputs} onClick={updateProject}>Save Project</button>
                                    <button className="btn btn-ad-danger" data-bs-toggle="modal" onClick={e => e.preventDefault()} data-bs-target="#confirmDelete">Delete Project</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="confirmDelete" data-bs-backdrop="static" data-bs-leopard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Confirm Project Deletion</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you absolutely sure you want to delete this project?</p>
                            <p>Deleting this project will <b>permanently</b> remove the <b>project</b>, <b>all rooms</b> contained within the project, and <b>all items</b> within those rooms.</p>
                            <p>If you're certain that you want to delete the project and everything contained within it, enter the project name below and click the "Delete Project" button.</p>

                            <label htmlFor="deleteConfirm" className="form-label">Enter "{project.name}" to delete the project:</label>
                            <input type="text" required className="form-control" name="deleteConfirm" id="deleteConfirm" onChange={updateConfirmation} value={deleteConfirmation || ""} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ad-secondary" data-bs-dismiss="modal">Go Back</button>
                            <button type="button" className="btn btn-ad-danger" data-bs-dismiss="modal" disabled={!allowDelete} onClick={deleteProject}>Delete Project</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        
    );
}

export default EditProject;